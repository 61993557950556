<template>
  <div class="mb-1">
    <b-badge class="cursor-pointer" variant="light-success" @click="select">
      {{ template }}
    </b-badge>
    <span>: {{ $t(template) }}</span>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import { BBadge } from 'bootstrap-vue';

export default {
  components: {
    BBadge,
  },
  props: {
    template: {
      type: String,
      required: true,
    },
  },
  methods: {
    select() {
      navigator.clipboard.writeText(this.template).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Copied'),
            icon: 'ClipboardIcon',
            variant: 'success',
          },
        });
      });
    },
  },
};
</script>
